import React from 'react'
import { graphql } from 'gatsby'
import { PodcastSeriePage } from '@/components'

const PodcastSerie = props => <PodcastSeriePage {...props} />

export default PodcastSerie

export const PodcastSeriePageQuery = graphql`
  query SinglePodcastSerieQuery($serie: String!, $lang: String!) {
    prismicPodcastpage(lang: { eq: $lang }) {
      data {
        subhead {
          text
        }
        episodes_heading {
          text
        }
        sidebar_heading {
          text
        }
        more_details {
          text
        }
        social_image {
          url
        }
      }
    }
    allPrismicPodcastepisode(
      filter: {
        data: { serie: { uid: { eq: $serie } } }
        lang: { eq: "en-us" }
      }
    ) {
      edges {
        node {
          uid
          data {
            title {
              text
            }
            summary {
              text
              html
            }
            episode
          }
        }
      }
    }
    prismicPodcastserie(uid: { eq: $serie }, lang: { eq: $lang }) {
      data {
        title {
          text
        }
        summary {
          html
          text
        }
        image {
          fluid(maxWidth: 400) {
            ...GatsbyPrismicImageFluid
          }
        }
        serie_color
      }
      uid
    }
  }
`
